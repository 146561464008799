import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import Button from "react-bootstrap/Button";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Коротко <span className="purple"> о преимуществах </span> TgPix:
            </h1>
            <p className="home-about-body">
              До 3х раз меньшая цена подписки на канал
              <br />
              <br /> Оптимизация за подписки на канал, за <b className="purple">диалоги, депозиты и продажи</b> вашим подписчикам
              <br />
              <br />
              <i>
                Оптимизация <b className="purple"> кастомных событий </b> нестандартных воронок, такие как запуск бота и др.
              </i>
              <br />
              <br />
                Не требует от клиента технических знаний или установки &nbsp; 
              <br />
              <br />
                Стабильная работа и 100% аптайм
              <br />
              <br />
                Неограниченное число кликов. Помесячный тариф, зависящий от кол-ва каналов &nbsp;
              <br />
              <br />
                Безгранично увеличиваем ваш ROI 
  
            
            </p>
          </Col>
          <Col md={4} className="myAvtar" >
              <img src={myImg} className="img-fluid" alt="avatar" style={{ borderRadius: "50%" }}/>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <Button
              variant="primary"
              href="https://t.me/ROI_Tech"
              target="_blank"
              style={{ marginLeft: "10px", marginBottom: "5px" }}
            >
              Пиши прямо сейчас
            </Button>
            <p>
              Всё настроим за <span className="purple">2 часа </span>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
