import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Оптимизируй свой пролив за целевые события",
          "Цена подписчика до 3х раз выгоднее",
          "Заливай за диалоги, продажи и депозиты",
          "TgPix от НИИ ROI",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
